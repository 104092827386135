// @flow
import React, { useLayoutEffect } from 'react';
import { PAIDY_HOST } from '../constants';

const PlusPage = () => {
  useLayoutEffect(() => {
    if (window) {
      window.location.href = `${PAIDY_HOST}/guide`;
    }
  }, []);

  return <></>;
};

export default PlusPage;
